<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addCommercialVehicleCategoriesModal' hide-footer>
        <addCommercialVehicleCategories @closeAddCommercialVehicleCategories='toggleAddCommercialVehicleCategories()' @addCommercialVehicleCategoriesSuccess='addCommercialVehicleCategoriesSuccess()'></addCommercialVehicleCategories>
      </b-modal>
      <b-modal ref='editCommercialVehicleCategoriesModal' hide-footer>
        <editCommercialVehicleCategories
          :editingCommercialVehicleCategories='currentCommercialVehicleCategories'
          @closeEditCommercialVehicleCategories='toggleEditCommercialVehicleCategories()'
          @editCommercialVehicleCategoriesSuccess='editCommercialVehicleCategoriesSuccess()'
        ></editCommercialVehicleCategories>
      </b-modal>
      <div class='row'>
        <h2>CommercialVehicleCategories</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddCommercialVehicleCategories()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addCommercialVehicleCategories from '../../components/addCommercialVehicleCategories';
import editCommercialVehicleCategories from '../../components/editCommercialVehicleCategories';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'Category',
                field: 'Category',
                sort: 'asc',
              },
              {
                label: 'DisplayOrder',
                field: 'DisplayOrder',
                sort: 'asc',
              },
              {
                label: 'Commercial',
                field: 'Commercial',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addCommercialVehicleCategoriesOpen: false,
      editCommercialVehicleCategoriesOpen: false,
      currentCommercialVehicleCategories: {}
    };
  },
  components: {
    addCommercialVehicleCategories,
    editCommercialVehicleCategories,
    Datatable,
  },
  created: function() {
    this.getCommercialVehicleCategories();
    this.addCommercialVehicleCategoriesOpen = false;
    this.editCommercialVehicleCategoriesOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getCommercialVehicleCategories() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getCommercialVehicleCategories', payload);
    },
    editItem(CommercialVehicleCategories) {
      this.toggleEditCommercialVehicleCategories();
      this.currentCommercialVehicleCategories = CommercialVehicleCategories;
    },
    toggleAddCommercialVehicleCategories() {
      if(this.addCommercialVehicleCategoriesOpen)
      {
        this.$refs.addCommercialVehicleCategoriesModal.hide()
      }
      else{
        this.$refs.addCommercialVehicleCategoriesModal.show()
      }
      this.addCommercialVehicleCategoriesOpen = !this.addCommercialVehicleCategoriesOpen;
    },
    addCommercialVehicleCategoriesSuccess() {
      this.toggleAddCommercialVehicleCategories();
      miniToastr['success']('CommercialVehicleCategories Added', 'Success', 1000, null);
      this.getCommercialVehicleCategories();
    },
    toggleEditCommercialVehicleCategories() {
      if(this.editCommercialVehicleCategoriesOpen)
      {
        this.$refs.editCommercialVehicleCategoriesModal.hide()
      }
      else{
        this.$refs.editCommercialVehicleCategoriesModal.show()
      }
      this.editCommercialVehicleCategoriesOpen = !this.editCommercialVehicleCategoriesOpen;
    },
    editCommercialVehicleCategoriesSuccess() {
      this.toggleEditCommercialVehicleCategories();
      miniToastr['success']('CommercialVehicleCategories Edited', 'Success', 1000, null);
      this.getCommercialVehicleCategories();
    }
  }
};
</script>
<style scoped>
.CommercialVehicleCategoriesThumbnail {
  height: 50px;
}
</style>
